
/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {FetchOptions} from 'ofetch';
import type {
  CurrentSessionDetails,
  NewPasswordRequest,
  PasswordRenewalInProgress,
  PasswordRenewalRequest,
} from '../models';
import {
    CurrentSessionDetailsFromJSON,
    CurrentSessionDetailsToJSON,
    NewPasswordRequestFromJSON,
    NewPasswordRequestToJSON,
    PasswordRenewalInProgressFromJSON,
    PasswordRenewalInProgressToJSON,
    PasswordRenewalRequestFromJSON,
    PasswordRenewalRequestToJSON,
} from '../models';

export interface RequestNewPasswordRequest {
    passwordRenewalRequest: PasswordRenewalRequest;
}

export interface SaveNewPasswordRequest {
    token: string;
    newPasswordRequest: NewPasswordRequest;
}

export interface VerifyTokenRequest {
    token: string;
}

/**
 * 
 */
export class PasswordRenewalApi extends runtime.BaseAPI {

    /**
     * Ask for a new password
     */
    async requestNewPasswordRaw(requestParameters: RequestNewPasswordRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<void>> {
        if (requestParameters['passwordRenewalRequest'] == null) {
            throw new runtime.RequiredError(
                'passwordRenewalRequest',
                'Required parameter "passwordRenewalRequest" was null or undefined when calling requestNewPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<void>({
            path: `/registration/api/password-renewal-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordRenewalRequestToJSON(requestParameters['passwordRenewalRequest']),
        }, initOverrides);

        return response;
    }

    /**
     * Ask for a new password
     */
    async requestNewPassword(requestParameters: RequestNewPasswordRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<void> {
        await this.requestNewPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Save a new password
     */
    async saveNewPasswordRaw(requestParameters: SaveNewPasswordRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<CurrentSessionDetails>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling saveNewPassword().'
            );
        }

        if (requestParameters['newPasswordRequest'] == null) {
            throw new runtime.RequiredError(
                'newPasswordRequest',
                'Required parameter "newPasswordRequest" was null or undefined when calling saveNewPassword().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request<CurrentSessionDetails>({
            path: `/registration/api/password-renewal/{token}/new-password`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters['token']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPasswordRequestToJSON(requestParameters['newPasswordRequest']),
        }, initOverrides);

        if (response._data) { response._data=CurrentSessionDetailsFromJSON(response._data); }
        return response;
    }

    /**
     * Save a new password
     */
    async saveNewPassword(requestParameters: SaveNewPasswordRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<CurrentSessionDetails> {
        const response = await this.saveNewPasswordRaw(requestParameters, initOverrides);
        return response._data;
    }

    /**
     * Verify that given token is valid and return the email of the account concerned
     */
    async verifyTokenRaw(requestParameters: VerifyTokenRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<runtime.FetchResponse<PasswordRenewalInProgress>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling verifyToken().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request<PasswordRenewalInProgress>({
            path: `/registration/api/password-renewal/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters['token']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (response._data) { response._data=PasswordRenewalInProgressFromJSON(response._data); }
        return response;
    }

    /**
     * Verify that given token is valid and return the email of the account concerned
     */
    async verifyToken(requestParameters: VerifyTokenRequest, initOverrides?: FetchOptions | runtime.InitOverrideFunction): Promise<PasswordRenewalInProgress> {
        const response = await this.verifyTokenRaw(requestParameters, initOverrides);
        return response._data;
    }

}
