/* tslint:disable */
/* eslint-disable */
/**
 * Registration API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PasswordRenewalRequest
 */
export interface PasswordRenewalRequest {
    /**
     * The email of the account for which to renew the password
     * @type {string}
     * @memberof PasswordRenewalRequest
     */
    email: string;
}

/**
 * Check if a given object implements the PasswordRenewalRequest interface.
 */
export function instanceOfPasswordRenewalRequest(value: object): value is PasswordRenewalRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function PasswordRenewalRequestFromJSON(json: any): PasswordRenewalRequest {
    return PasswordRenewalRequestFromJSONTyped(json, false);
}

export function PasswordRenewalRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordRenewalRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function PasswordRenewalRequestToJSON(value?: PasswordRenewalRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

