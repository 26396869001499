import {pushVJoySnackbar} from '@maltjoy/core-vue';
import {defineStore, storeToRefs} from 'pinia';
import {ref} from 'vue';
import {useLogger, useNuxtApp, useTranslation} from '#imports';
import {readonlyState} from '#malt/nuxt-utils-module';
import type {CurrentSessionDetails, FetchError} from '@api';
import {PasswordRenewalApi} from '@api';
import {useRedirectAfterSignin} from '~/composables/useRedirectAfterSignin';
import {apiConfiguration} from '~/configuration/apiConfiguration';
import {useSigninStore} from './signin.store';

interface ResponseWrapper<T> {
    data: T | null;
    error: FetchError | null;
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const usePasswordRenewalStore = defineStore('password-renewal-store', () => {
    const {$pinia} = useNuxtApp();
    const logger = useLogger();
    const {t} = useTranslation();

    const passwordRenewalApi = new PasswordRenewalApi(apiConfiguration);

    const signinStore = useSigninStore($pinia);
    const {emailModel: signinEmail} = storeToRefs(signinStore);

    const emailModel = ref('');
    const passwordModel = ref('');
    const passwordConfirmationModel = ref('');
    const redirectTo = ref<string | null>(null);

    const requestNewPasswordAllowed = ref(true);

    const pendingRequest = ref(false);

    function pushPermanentNotification(message: string, level: 'error' | 'success' = 'success') {
        pushVJoySnackbar({props: {message, level, duration: 'forever'}});
    }

    async function requestNewPassword(): Promise<void> {
        if (!requestNewPasswordAllowed.value) {
            return;
        }

        if (!signinEmail.value) {
            pushPermanentNotification(t('registration.password.renewal.error.email.invalid'), 'error');
            return;
        }

        requestNewPasswordAllowed.value = false;
        try {
            const payload = {
                passwordRenewalRequest: {
                    email: signinEmail.value,
                },
            };
            await passwordRenewalApi.requestNewPassword(payload);
            pushPermanentNotification(t('registration.password.renewal.email.sent'));
        } catch (e) {
            const error = e as FetchError;
            if (error.statusCode === 400) {
                pushPermanentNotification(t('registration.password.renewal.error.email.invalid'), 'error');
            } else {
                logger.error(error);
                pushPermanentNotification(t('registration.signin.server.error'), 'error');
            }
        } finally {
            await sleep(2000);
            requestNewPasswordAllowed.value = true;
        }
    }

    async function verifyToken(token: string): Promise<void> {
        const payload = {token};
        const response = await passwordRenewalApi.verifyToken(payload);
        if (response.email) {
            emailModel.value = response.email;
        }
    }

    async function saveNewPassword(token: string): Promise<ResponseWrapper<CurrentSessionDetails>> {
        try {
            pendingRequest.value = true;
            const payload = {
                token,
                newPasswordRequest: {email: emailModel.value, newPassword: passwordModel.value},
            };
            const data = await passwordRenewalApi.saveNewPassword(payload);
            redirectTo.value = useRedirectAfterSignin().getRedirectTo(data);
            return {data, error: null};
        } catch (e) {
            const error = e as FetchError;
            if (error.statusCode === 400) {
                pushVJoySnackbar({props: {message: t('registration.password.renewal.server.error.email.unknown'), level: 'error'}});
            } else {
                logger.error(e);
                pushVJoySnackbar({props: {message: t('registration.signin.server.error'), level: 'error'}});
            }
            return {data: null, error: e as FetchError};
        } finally {
            pendingRequest.value = false;
        }
    }

    return {
        requestNewPassword,
        verifyToken,
        saveNewPassword,
        pendingRequest: readonlyState(pendingRequest),
        emailModel: readonlyState(emailModel),
        passwordModel: readonlyState(passwordModel),
        passwordConfirmationModel: readonlyState(passwordConfirmationModel),
        redirectTo: readonlyState(redirectTo),
    };
});
